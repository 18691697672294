<!--
    Paramètres:
    - title = Le titre du modal
    - time = temps depuis l'action si ce n'est pas maintenant
    - message
    - type = Choix pour la couleur du toast

    Comment utiliser le toast:
    - L'inclure au bas du template de la page
    - Appelé la fonction setEverything() en utilisant le ref pour ajouter les valeurs
    - Appelé la fonction show() pour l'affiché
-->

<template>
    <div class="toast-container position-fixed p-3" :class="isBottom ? 'bottom-0 end-0' : 'top-0 start-50 translate-middle-x'">
        <div ref="liveToast" id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto" ref="title">{{ title ? title : 'Gesware' }}</strong>
                <small ref="time">{{ time ? time : '' }}</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" ref="close"></button>
            </div>
            <div class="toast-body" ref="message">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .toast-body {
        white-space: pre-line;
    }
</style>

<script>
import { Toast } from "bootstrap";

export default {
    name: "ToastComponent",
    props: {
        isBottom: Boolean,
    },
    data() {
        return {
            toast: {},
            title: '',
            time: '',
            message: '',
            type: '',
        }
    },
    mounted() {
        this.$refs.liveToast.addEventListener('hidden.bs.toast', () => { this.clearSettings(); });
    },
    methods: {
        show() {
            this.setClasses();
            this.toast.show();
        },
        hide() {
            this.toast.hide();
        },
        setEverything(title, time, message, type, isAutoHide = true) {
            this.title = title;
            this.time = time;
            this.message = message;
            this.type = type;
            this.toast = new Toast(this.$refs.liveToast, {autohide: isAutoHide});
        },
        clearSettings() {
            this.title = '';
            this.time = '';
            this.message = '';
            this.type = '';
        },
        setClasses() {
            this.$refs.liveToast.classList.remove('text-bg-info', 'text-bg-success', 'text-bg-warning', 'text-bg-danger');
            switch (this.type) {
                case 'info':
                    this.$refs.liveToast.classList.add('text-bg-info');
                    break;
                case 'save':
                    this.$refs.liveToast.classList.add('text-bg-success');
                    break;
                case 'warn':
                    this.$refs.liveToast.classList.add('text-bg-warning');
                    break;
                case 'error':
                    this.$refs.liveToast.classList.add('text-bg-danger');
                    break;
                default:
                    break;
            }
        },
    }
}
</script>