<style scoped>

</style>

<template>
    <ModalComponent ref="modal" title="Mémos Sauvegardés" size="modal-xl" :confirm="true">
        <table class="table table-hover table-bordered text-center text-xs">
            <thead>
                <tr :class="`bg-${this.$route.meta.theme}`">
                    <th></th>
                    <th>De</th>
                    <th>Date</th>
                    <th>Sujet</th>
                    <th>Contenu</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="memo in memos" class="align-middle">
                    <td>
                        <div class="btn-group btn-group-sm">
                            <button class="btn btn-light btn-sm" v-tooltip data-bs-toggle="tooltip" @click.prevent="deleteMemo(memo)"
                                    data-bs-placement="top" data-bs-title="Supprimer" data-bs-trigger="hover"
                            >
                                <i class="fa-solid fa-trash"></i>
                            </button>
                            <button class="btn btn-light btn-sm" v-tooltip data-bs-toggle="tooltip" @click.prevent="resetMemo(memo)"
                                    data-bs-placement="top" data-bs-title="Réinitialiser" data-bs-trigger="hover"
                            >
                                <i class="fa-solid fa-arrow-up"></i>
                            </button>
                        </div>
                    </td>
                    <td>{{ this.$general.flipEmployeeNames(memo.employe, 'full_name')?.full_name }}</td>
                    <td>{{ this.$general.formatDate(memo.date) }}</td>
                    <td>{{ memo.subject }}</td>
                    <td class="text-start"><div v-html="memo.content"></div></td>
                </tr>
            </tbody>
        </table>
    </ModalComponent>
</template>

<script>

import ModalComponent from "@/components/_shared/ModalComponent.vue";
import memo from "@/components/navbar/memo/Memo.vue";

export default {
    name: "SavedMemo",
    components: {ModalComponent},
    props: {},
    data() {
        return {
            memos: [],
        }
    },
    computed: {
        memo() {
            return memo
        }
    },
    methods: {
        async open() {
            this.memos = await this.$store.memoStore.getSavedMemos();
            this.$refs.modal.open();
        },
        async updateMemo(memo) {
            await this.$store.memoStore.updateRecipient(memo);
            this.memos = await this.$store.memoStore.getSavedMemos();
            this.$parent.reopenMemo();
        },
        deleteMemo(memo) {
            memo.recipients[0].is_deleted = true;
            this.updateMemo(memo);
        },
        resetMemo(memo) {
            memo.recipients[0].is_saved = false;
            memo.recipients[0].is_read = false;
            this.updateMemo(memo);
        },
    },
    watch: {},
}
</script>