<style scoped>

</style>

<template>
    <ModalComponent ref="modal" title="Paramètres Personnel" :confirm="true">
        <div class="container-fluid">
            <div class="row">
                <div class="col input-group input-group-sm align-items-center">
                    <div class="d-inline-block input-group-text">Affichage des Noms:</div>
                    <div class="ms-2 form-check form-switch d-inline-block">
                        <input class="form-check-input" type="checkbox" role="switch"
                               id="flexSwitchCheckChecked" :checked="isTextFlipped" @change="toggleFlipText()">
                        <label class="ms-1 form-check-label" for="flexSwitchCheckChecked">Exemple: {{ exampleFullName }}</label>
                    </div>
                </div>
            </div>
        </div>
    </ModalComponent>
</template>

<script>
    import ModalComponent from "@/components/_shared/ModalComponent.vue";

    export default {
        name: "PersonalSettings",
        components: {ModalComponent},
        props: {},
        data() {
            return {
                isTextFlipped: false,
            }
        },
        computed: {
            exampleFullName() {
                return this.$general.flipEmployeeNames(this.$store.authUserStore.fullUserDetails, 'full_name')?.full_name
            },
        },
        methods: {
            open() {
                this.isTextFlipped = this.$store.rememberStore.getFieldValue(this.$.type.name, 'isTextFlipped');
                this.$refs.modal.open();
            },
            toggleFlipText() {
                this.isTextFlipped = !this.isTextFlipped;
                this.updateVariable('isTextFlipped', this.isTextFlipped);
            },
            updateVariable(variableName, variable) {
                this.$store.rememberStore.updatePage(this.$.type.name, variableName, variable);
            }
        },
        watch: {},
    }
</script>
