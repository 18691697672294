<style scoped>
    .timer {
        color: white;
        font-size: 12px;
    }
</style>
<template>
    <div class="timer" v-if="isLoaded && timeRemaining >= 0">
        Mise à jour dans 
        <template v-if="timeRemaining > timeAdvancedCountdown">
            <template v-if="daysRemaining > 0">
                {{ formatUnit(daysRemaining, 'jour') }}
            </template>
            <template v-if="hoursRemaining > 0">
                {{ formatUnit(hoursRemaining, 'heure') }}
            </template>
        </template>
        <template v-else>
            <template v-if="countdown.hours > 0">
                {{ formatUnit(countdown.hours, 'heure') }}
            </template>
            <template v-if="countdown.minutes > 0 || countdown.hours > 0">
                {{ formatUnit(countdown.minutes, 'minute') }}
            </template>
            <template v-if="timeRemaining <= hourInSeconds">
                {{ formatUnit(countdown.seconds, 'seconde') }}
            </template>
        </template>
    </div>
</template>
  
<script>
    export default {
        name: "updateCountdown",
        data() {
            return {
                hourInSeconds: 3600,
                updateTime: null,
                timeRemaining: 0,
                interval: null,
                countdown: { hours: 0, minutes: 0, seconds: 0 },
                isLoaded: false,
                timeAdvancedCountdown: 8 * 3600, // 8 hours
                maintenancePopupInterval: [30, 15, 5, 4, 3, 2, 1]
            };
        },
        computed: {
            daysRemaining() {
                return Math.floor(this.timeRemaining / (this.hourInSeconds * 24));
            },
            hoursRemaining() {
                return Math.floor((this.timeRemaining % (this.hourInSeconds * 24)) / this.hourInSeconds);
            },
        },
        created() {
            this.getNextUpdate();
        },
        beforeUnmount() {
            clearInterval(this.interval);
        },
        methods: {
            getNextUpdate() {
                this.$axios.get('/GetNextUpdate').then((response) => {
                    this.updateTime = response.start_time;

                    this.updateCountdown();
                    this.adjustInterval();
                    this.isLoaded = true;
                });
            },
            calculateTimeDifference() {
                this.timeRemaining = this.$general.getDiff(this.updateTime, this.$general.convertFromUserTimezone(), 'second');
            },
            calculateCountdown() {
                const durationObj = this.$general.getDuration(this.timeRemaining, 'seconds');
                this.countdown = { 
                    hours: durationObj.hours(), 
                    minutes: durationObj.minutes(), 
                    seconds: durationObj.seconds()
                };
            },
            updateCountdown() {
                this.calculateTimeDifference();
                if (this.timeRemaining <= this.timeAdvancedCountdown) {
                    this.calculateCountdown();
                }

                if (this.timeRemaining === 0) {
                    this.disconnectUser();
                }

                if (this.maintenancePopupInterval.includes((this.timeRemaining / 60))) {
                    this.$parent.$parent.showMaintenancePopup(this.countdown.minutes);
                }
                this.adjustInterval();
            },
            adjustInterval() {
                if (this.interval) {
                    clearInterval(this.interval);
                }

                if (this.timeRemaining > this.timeAdvancedCountdown && this.timeRemaining <= 86400) { // Between 1 day and 8 hours (1 day = 86400 seconds)
                    this.interval = setInterval(this.updateCountdown, 600000); // 10 minutes interval (600000 ms)
                } else if (this.timeRemaining > this.hourInSeconds && this.timeRemaining <= this.timeAdvancedCountdown) { // Between 8 hours and 1 hour (this.hourInSeconds seconds)
                    this.interval = setInterval(this.updateCountdown, 60000); // 1 minute interval (60000 ms)
                } else if (this.timeRemaining <= this.hourInSeconds) { // Less than 1 hour
                    this.interval = setInterval(this.updateCountdown, 1000); // 1 second interval
                }                
            },
            disconnectUser() {
                this.$store.authUserStore.logout();
                this.$router.push({ name: 'Login' });
            },
            formatUnit(value, unit) {
                return `${value} ${unit}${value > 1 ? 's' : ''} `;
            },
        },
    };
</script>
