import axios from 'axios';
import { CancelToken } from 'axios';
import { useAuthUserStore } from "@/store/auth.js";

const retryMaxAttempts = 1;
const MAX_REQUESTS = import.meta.env.VITE_APP_MAX_THROTTLE || 1600;
const THROTTLE_PERIOD = 60 * 1000; // 60 seconds

let requestQueue = [];
let cancelTokenSource = null;

axios.defaults.baseURL = `${import.meta.env.VITE_API_SECURITY || 'http'}://${import.meta.env.VITE_API_ENDPOINT}:${import.meta.env.VITE_API_PORT}/api/`;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default class AxiosClass {

    constructor() {
        // Intercept request and add to queue if not throttled
        axios.interceptors.request.use((config) => {
            return new Promise((resolve, reject) => {
                const now = Date.now();

                // Clean up the request queue by removing expired requests
                requestQueue = requestQueue.filter(request => now - request.timestamp < THROTTLE_PERIOD);

                // Check if the number of requests exceeds the limit
                if (requestQueue.length >= MAX_REQUESTS) {
                    // Throttle the request
                    (useAuthUserStore()).isThrottled = true;
                    reject(new Error('Request throttled'));
                } else {
                    // Add the request to the queue and resolve the promise
                    requestQueue.push({ config, timestamp: now });
                    resolve(config);
                }
            });
        });

        // Clear throttle flag on successful responses
        axios.interceptors.response.use(
            response => {
                (useAuthUserStore()).isThrottled = false;
                return response;
            },
            error => {
                (useAuthUserStore()).isThrottled = false;
                this.cancelAllRequests();
                return Promise.reject(error);
            }
        );
    }

    setTokenInfo (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    };

    get = async (url, type = "object", attempt = 0) => {
        let response; let status;

        cancelTokenSource = CancelToken.source();
        await axios.get(url, { cancelToken: cancelTokenSource.token })
            .then((resp) => { response = resp.data; status = resp.status; })
            .catch((error) => {
                if (error.message === 'Request throttled') {
                    // Return an empty array if the request is throttled
                    response = [];
                } else {
                    this.handleErrors(error);
                }
            });

        if (typeof response !== type && !(typeof response === "number" && [0,1].includes(response)) && status < 400 && attempt < retryMaxAttempts) {
            return this.get(url, type, ++attempt);
        } else {
            return response;
        }
    };

    post = async (url, data, type = "object", attempt = 0) => {
        let response; let status;

        cancelTokenSource = CancelToken.source();
        await axios.post(url, data, { cancelToken: cancelTokenSource.token })
            .then((resp) => { response = resp.data; status = resp.status; })
            .catch((error) => {
                if (error.message === 'Request throttled') {
                    // Return an empty array if the request is throttled
                    response = [];
                } else {
                    this.handleErrors(error);
                }
            });

        if (typeof response !== type && !(typeof response === "number" && [0,1].includes(response)) && status < 400 && attempt < retryMaxAttempts) {
            return this.post(url, data, type, ++attempt);
        } else {
            return response;
        }
    };

    pdf = async (url, data) => {
        let response;
        await axios({url:url, data:data, method: 'POST', responseType: 'blob'})
            .then((resp) => { response = resp; })
            .catch((error) => { this.handleErrors(error); });
        return response;
    };
    
    put = async (url, data) => {
        let response;

        cancelTokenSource = CancelToken.source();
        await axios.put(url, data, { cancelToken: cancelTokenSource.token })
            .then((resp) => { response = resp.data; })
            .catch((error) => { this.handleErrors(error); });
        return response;
    };

    handleErrors = (error) => {
        const authUserStore = useAuthUserStore();

        switch (error.response?.status) {
            case 401:
                authUserStore.$reset();
                location.href = "/";
                break;
            case 403:
                location.href = "/forbidden";
                break;
            case 429:
                //TODO: Deal with Too many requests.
                break;
            default:
                console.log(error);
                break;
        }
    }

    cancelAllRequests() {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled due to throttling');
            cancelTokenSource = null;
        }
    }
}