import { Tooltip } from 'bootstrap';

export const tooltip = {

    mounted(el) {
        const tooltip = new Tooltip(el);
        if (tooltip._getConfig().isPermissionDenied) {
            el.setAttribute('data-bs-original-title', `Vous n'avez pas la permission pour faire cette action`);
            el.setAttribute('data-bs-original-toggle', 'tooltip');
            el.setAttribute('data-bs-original-placement', 'toggle');
            el.setAttribute('data-bs-original-trigger', 'hover');

            if (tooltip._getConfig().title.length > 0) {
                tooltip.setContent({ '.tooltip-inner': tooltip._getConfig().originalTitle });
            }
        }
    },

    unmounted(el) {
        const tooltip = Tooltip.getInstance(el);

        if (tooltip) {
            setTimeout(() => tooltip.dispose(), 500);
        }
    },

};