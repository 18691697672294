<style scoped>

</style>

<template>
    <ModalComponent ref="modal" title="Nouveau mémo" size="modal">
        <div class="ps-3 pe-3 text-xs">
            <div class="row mb-3">
                <h6 class="mb-1">Mémo</h6>
                <hr class="mb-2">
                <div>
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">* Date</span>
                        <VueDatePicker v-model="dates" multi-dates :enable-time-picker="false" class="form-control" select-text="Choisir" cancel-text="Annuler" locale="fr-CA"/>
                    </div>
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">* Sujet</span>
                        <input type="text" class="form-control form-control-sm" v-model="subject" @keyup="setDataChanged(true)" maxlength="100" required>
                    </div>
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">* Contenu</span>
                        <textarea class="form-control form-control-sm" v-model="content" @keyup="setDataChanged(true)" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <h6 class="mb-1">Destinataires</h6>
                <hr class="mb-2">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr class="text-center" :class="`bg-${this.$route.meta.theme}`">
                            <th>
                                <input type="checkbox" class="align-bottom" :checked="isAllChecked" v-model="isAllChecked" @change="toggleCheckAll()">
                            </th>
                            <th>Nom</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employe in employes">
                            <td class="text-center">
                                <input type="checkbox" class="align-bottom" v-model="employe.isChecked" @change="setDataChanged(true)">
                            </td>
                            <td>{{ employe.full_name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ModalComponent>
    <ToastComponent ref="toast"></ToastComponent>
</template>

<script>

import ModalComponent from "@/components/_shared/ModalComponent.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ToastComponent from "@/components/_shared/ToastComponent.vue";

export default {
    name: "NewMemo",
    components: {ToastComponent, VueDatePicker, ModalComponent},
    props: {},
    data() {
        return {
            employes: [],
            dates: [],
            subject: "",
            content: "",
            isAllChecked: false,
            dataChanged: false,
        }
    },
    computed: {
        canSave() {
            const checkedEmployes = this.employes.filter(emp => emp.isChecked === true);
            return ((this.dates !== null && this.dates.length > 0) && this.subject.length > 0 && this.content.length > 0 && checkedEmployes.length > 0);
        },
    },
    methods: {
        getEmployes() {
            if (this.employes.length > 0) { return; }

            this.$axios.get('/EmployeList').then((response) => {
                this.employes = this.$general.orderByCustom(response, 'full_name', true);
            });
        },
        async open() {
            this.reset();
            this.getEmployes();

            this.dates.push(new Date());
            await this.$nextTick(() => {
                this.setDataChanged(false);
            })

            this.$refs.modal.open();
        },
        toggleCheckAll() {
            this.employes.forEach(employe => {
                employe.isChecked = this.isAllChecked;
            });
            this.setDataChanged(true);
        },
        setDataChanged(value) {
            if (value === this.dataChanged) {
                if (!this.canSave) { this.dataChanged = false; }
                return;
            }
            this.dataChanged = (!this.canSave ? false : value);
        },
        save() {
            this.$axios.post('/Memo/CreateNew', {dates: this.dates, subject: this.subject, content: this.content.replace(/\n/g, "<br />"), employes: this.employes}).then((response) => {
                if (response) {
                    this.$refs.toast.setEverything('Nouveau Mémo','','Votre nouveau mémo à bien été ajouté.','save');
                    this.$refs.toast.show();
                    this.$refs.modal.close();
                } else {
                    this.$refs.toast.setEverything('Nouveau Mémo','','Une erreur s\'est produite lors de la création de votre nouveau mémo.','error');
                    this.$refs.toast.show();
                }
            });
        },
        reset() {
            this.employes = [];
            this.dates = [];
            this.subject = "";
            this.content = "";
            this.isAllChecked = false;
            this.setDataChanged(false);
        }
    },
    watch: {
        dates: {
            handler() {
                this.setDataChanged(true);
            }
        }
    },
}
</script>