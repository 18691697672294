import {defineStore} from "pinia";
import { usePermissionStore } from "@/store/perms.js";

import AxiosClass from "@/classes/axios.js";
const axios = new AxiosClass();

export const useMemoStore = defineStore('memo', {
    state: () => ({
        listOfMemo: [],
        savedMemo: [],
        futureMemo: [],
        forceOpen: false,
        route: "",
        action: "",
    }),
    getters: {

    },
    actions: {
        getAllMemos() {
            const permissionStore = usePermissionStore();
            if (!permissionStore.canAccess('can_view_memos')) { return; }
            axios.get('/Memo/All').then((data) => {
                if (data !== undefined) {
                    this.listOfMemo = data;
                }
            });
            return this.listOfMemo;
        },
        async getSavedMemos() {
            await axios.get('/Memo/Saved').then((data) => {
                if (data !== undefined) {
                    this.savedMemo = data;
                }
            });
            return this.savedMemo;
        },
        async getFutureMemos() {
            await axios.get('/Memo/Future').then((data) => {
                if (data !== undefined) {
                    this.futureMemo = data;
                }
            });
            return this.futureMemo;
        },
        async updateRecipient(memo, route = null, action = null) {
            await axios.post('/Memo/UpdateRecipient', {memo: memo}).then(async () => {
                await this.fetchAllMemos();

                if (route !== null && action !== null) {
                    this.route = route;
                    this.action = action;
                }
            });
        },
        async fetchAllMemos() {
            await this.getAllMemos();
            await this.getSavedMemos();
            await this.getFutureMemos();
        },
        setForceOpen(isOpen) {
            this.forceOpen = (isOpen && this.listOfMemo.length > 0);
        },
        clear () {
            this.$reset();
        },
    },
    persist: {
        storage: sessionStorage,
        paths: ['listOfMemo','savedMemo','futureMemo'],
    },
})