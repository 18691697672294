<!--
    Paramètres:
    - title = Le titre du modal
    - message

    Comment utiliser le confirmDialog:
    - L'inclure au bas du template de la page
    - Ajouter une fonction beforeRouteLeave comme ceci:
        async beforeRouteLeave() {

            if(!this.dataChanged) { return; }

            const confirm = await this.$refs.confirmDialog.show({
                title: 'Gesware',
                message: 'Des changements n\'ont pas été sauvegardés. \nVoulez-vous sauvegarder avant de quitter?',
            });

            if (confirm === true) {
                this.save();
            } else return confirm === "deny";
        },
    - Modifier la fonction ajoutée selon les besoin pour faire certaines actions.
-->
<template>
    <ModalComponent ref="modal" :title="title" :confirm="true" :prevent-reset-inactivity-timer="true">
        <p class="pre-formatted">{{ message }}</p>
        <div class="btn-container">
            <div class="btn-group" role="group">
                <button type="button" class="btn btn-light" @click="_confirm"   :disabled="!isSaveValid">
                    <i class="fa-solid fa-xl" :class="confirmClassIcon" />
                    Oui
                </button>
                <button type="button" class="btn btn-light" @click="_deny">
                    <i class="fa-solid fa-x"></i>
                    Non
                </button>
                <button type="button" class="btn btn-light" v-if="showCancel" @click="_cancel">
                    <i class="fa-solid fa-ban fa-rotate-90"></i>
                    Rester
                </button>
            </div>
        </div>
    </ModalComponent>
</template>

<style scoped>
    .btn-container {
        display: flex;
        justify-content: flex-end;
    }
    .fa-x{
        color: red;
    }
    .fa-floppy-disk {
        color: green;
    }
    .pre-formatted {
        white-space: pre;
    }
</style>

<script>
import ModalComponent from "@/components/_shared/ModalComponent.vue";

export default {
    name: "ConfirmDialog",
    components: {ModalComponent},
    props: {
        isSaveValid : Boolean,
    },
    data() {
        return {
            title: undefined,
            message: undefined,
            showCancel: true,
            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
            hasResetMethod: false,
            confirmClassIcon: 'fa-floppy-disk',
        }
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            
            if (opts.showCancel !== undefined) {
                this.showCancel = opts.showCancel;
            }
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            if (opts.hasResetMethod) {
                this.hasResetMethod = opts.hasResetMethod;
            }
            if (opts.isConfirmIconCheck) {
                this.confirmClassIcon = 'fa-check text-green';
            }

            this.$refs.modal.open();

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.modal.close()
            this.resolvePromise(true)
        },

        _deny() {
            if(this.hasResetMethod) {
                this.$parent.reset();
            }
            this.$refs.modal.close()
            this.resolvePromise('deny')
        },

        _cancel() {
            this.$refs.modal.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User canceled the dialogue'))
        },
    },
}
</script>