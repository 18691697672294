import shelfImage from '@/assets/shelf.svg';
import { useEnumsStore } from '@/store/enums.js';
export default class StatusIcon {

    getOrderStatusIcon(item, size = "", showReturn = false) {
        const enumsStore = useEnumsStore();

        let returnClass = "";
        if([enumsStore.MaterialOperationType.RM.id, enumsStore.MaterialOperationType.RI.id].includes(item?.material_operation_type_id)) {
            if(item?.transaction) {
                item['is_confirmed'] = ![undefined, null].includes(item.transaction?.transaction_return?.transaction_id);
            }

            if(!!item['is_confirmed']) {
                returnClass = ((item?.material_operation_type_id === enumsStore.MaterialOperationType.RM.id) ? "text-operation" : "inventory-blue");
            }
        }
        switch(item?.material_operation_type_id) {
            case enumsStore.MaterialOperationType.MC.id:
                return `<i class="fa-solid fa-industry ${size}"></i>`;
            
            case enumsStore.MaterialOperationType.BC.id:
                return `<i class="fa-solid fa-industry ${((item['is_confirmed']) ? 'text-purple-600' : 'text-operation' )} ${size}"></i>`;

            case enumsStore.MaterialOperationType.BI.id:
                return `<img class="inventory-img inventory-blue inline-block" src="${shelfImage}" alt="">`;

            case enumsStore.MaterialOperationType.BE.id:
                return `<i class="fa-solid fa-industry text-green-600 ${size}"></i>`;

            case enumsStore.MaterialOperationType.BP.id:
                return 'BP';

            case enumsStore.MaterialOperationType.BR.id:
                return `<i class="fa-solid fa-house-chimney ${((item['is_completed']) ? 'text-general-pale' : 'text-operation' )} ${size}"></i>`;

            case enumsStore.MaterialOperationType.RM.id:
                if(showReturn) {
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-industry ${((item['is_completed']) ? 'text-general-pale' : returnClass)} ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                } else {
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-industry ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                }

            case enumsStore.MaterialOperationType.RI.id:
                if(!showReturn) {
                    return '<span class="stack-icon-custom">' +
                                `<img src="${shelfImage}" class="inventory-img" style="display: inline;" alt="">` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                } else {
                    return '<span class="stack-icon-custom">' +
                                `<img src="${shelfImage}" class="inventory-img ${((item['is_completed']) ? 'inventory-pale' : returnClass)}" style="display: inline;" alt="">` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon" style="bottom: -4px;"></i>' +
                            '</span>';
                }

            case enumsStore.MaterialOperationType.XX.id:
                return `<i class="fa-solid fa-circle-xmark ${size}"></i>`;

            default:
                return '';
        }
    };

    getShippingStatusIcon(item, size = "", orderStatus = "") {
        const enumsStore = useEnumsStore();
        
        if(orderStatus === enumsStore.MaterialOperationType.XX.id) {
            return `<i class="fa-solid fa-circle-xmark ${size}"></i>`;
        } else {
            switch(item?.material_operation_type_id) {
                case enumsStore.MaterialOperationType.BL.id:
                    return `<i class="fa-solid fa-truck ${((item['is_completed']) ? 'text-general-pale' : 'text-green' )} ${size}"></i>`;
    
                case enumsStore.MaterialOperationType.BA.id:
                    return `<i class="fa-solid fa-tools text-green ${size}"></i>`;
    
                case enumsStore.MaterialOperationType.RL.id:
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-truck ${((item['is_completed']) ? 'text-general-pale' : 'text-green' )} ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
    
                default:
                    return '';                            
            }
        }
    };
}