<!--
    Paramètres:
    - title = Le titre du modal
    - size = La grandeur en format classe css (modal-small, modal-sm, modal-lg, modal-xl, modal-xxl, modal-3xl, modal-4xl)

    Comment utiliser le modal:
    - L'inclure au bas du template de la page
    - En utilisant les refs placée dans les pages on peux appeler le modal de cette manière
        this.$refs.form.$refs.modal.open();

-->
<style scoped>
    .modal-header {
        height: 10px;
    }

    .modal-title {
        margin: 0 auto;
        width: 100%;
    }

    .modal-content {
        overflow: inherit;
        padding-bottom: 10px;
    }

    .modal-body {
        max-height: 75vh;
        overflow: auto;
    }
</style>

<template>
    <div class="modal" :class="{ show: isVisible }" :style="isModalVisible"
        id="Modal" tabindex="-1" aria-labelledby="ModalLabel"    
    >
        <div class="modal-dialog modal-dialog-centered" :class="size" :style="getModalStyle">
            <div class="modal-content">
                <form @submit.prevent="save()">
                    <div class="modal-header" :class="`bg-${(bgColor !== undefined) ? bgColor : this.$route.meta.theme}`" 
                        @mousedown="startDragging"
                        @mousemove="dragModal"
                        @mouseup="stopDragging"
                    >
                        <button type="submit" v-show="!confirm" class="save-button-modal" :disabled="!this.$parent.dataChanged">
                            <i class="fa-solid fa-floppy-disk fa-xl"></i>
                        </button>
                        <div class="modal-title text-center">{{ title }}</div>
                        <template v-if="!overrideCloseBtn">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                        </template>
                        <template v-else>
                            <slot name="closeBtn"></slot>
                        </template>
                    </div>
                    <div class="modal-body" :class="this.$parent.$.type.name" :style="{'min-height': (this.height !== undefined) ? this.height : ''}">
                        <slot></slot>
                    </div>
                </form>
            </div>
        </div>
        <div @click="close" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
export default {
    name: "ModalComponent",
    props: {
        title: String,
        size: String,
        confirm: Boolean,
        canReset: Boolean,
        bgColor: String,
        overrideCloseBtn: Boolean,
        forceOpenConfirm: Boolean,
        isForceRefresh: Boolean,
        preventModalMovement: Boolean,
        preventResetInactivityTimer: {
            type: Boolean,
            default: false
        },
        height: String,
    },
    data() {
        return {
            isVisible: false,
            isDragging: false,
            startX: 0,
            startY: 0,
            modalX: 0,
            modalY: 0
        }
    },
    computed: {
        isModalVisible() {
            return { display: this.isVisible ? 'block' : 'none' };
        },
        getModalStyle() {
            return (this.preventModalMovement !== true) ? { transform: `translate(${this.modalX}px, ${this.modalY}px)` } : null;
        },
    },
    methods: {
        open() {
            this.isVisible = true;
            if(!this.preventResetInactivityTimer) {
                this.$store.authUserStore.resetInactivityTimer();
            }
        },
        startDragging(event) {
            if (this.preventModalMovement === true) { return; }
            this.isDragging = true;
            this.startX = event.clientX - this.modalX;
            this.startY = event.clientY - this.modalY;
        },
        dragModal(event) {
            if (this.preventModalMovement === true) { return; }
            if (this.isDragging) {
                this.modalX = event.clientX - this.startX;
                this.modalY = event.clientY - this.startY;
            }
        },
        stopDragging() {
            this.isDragging = false;
        },
        async close() {
            if(!this.preventResetInactivityTimer) {
                this.$store.authUserStore.resetInactivityTimer();
            }

            let event = await this.setupDialog();
            if (event === "stay") { return; }
            this.isVisible = false;

            this.$parent.closing?.();

            if (this.isForceRefresh && (["Material", "Order List", "Order List BE"].includes(this.$route.name))) {
                window.location.reload();
            }
        },
        async setupDialog() {
            if (!this.forceOpenConfirm && (!this.$parent.dataChanged || this.$parent.$refs.confirmDialog === undefined)) {
                this.isVisible = false;
                this.reset();
                return;
            }

            const confirm = await this.$parent.$refs.confirmDialog.show({
                title: 'Gesware',
                message: 'Des changements n\'ont pas été sauvegardés. \nVoulez-vous sauvegarder avant de quitter?',
            })

            if (confirm === true) {
                this.save();
            } else if (confirm === false) {
                if (this.$parent.waitChangePopup) {
                    this.$parent.cancelChangePopup = true;
                }
                return "stay";
            } else {
                this.reset();
            }
        },
        save() {
            if(this.$parent.waitChangePopup) {
                this.$parent.cancelChangePopup = false;
            }
            this.$parent.save?.();
        },
        reset() {
            if(this.canReset) {
                if(this.$parent.waitChangePopup) {
                    this.$parent.cancelChangePopup = false;
                }
                this.$parent.reset?.();
            }
        },
    }
}
</script>