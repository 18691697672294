<style scoped>

</style>

<template>
    <ModalComponent ref="modal" :title="`Apercu - ${filename}`" size="modal-xl" :confirm="true" :prevent-modal-movement="true" height="90vh">
        <div class="row mb-2">
            <div class="btn-group btn-group-xs col-1">
                <button class="btn btn-light" v-tooltip data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Télécharger"
                        data-bs-trigger="hover" @click="downloadPDF()">
                    <i class="fa-solid fa-download fa-xs"></i>
                </button>
                <button class="btn btn-light" v-tooltip data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Imprimer"
                        data-bs-trigger="hover" @click="printPDF()">
                    <i class="fa-solid fa-print fa-xs"></i>
                </button>
            </div>
            <div class="btn-group btn-group-xs col-1">
                <button class="btn btn-light btn-xs" v-tooltip data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Réinitialiser le zoom"
                        data-bs-trigger="hover" @click="zoom = 100; refreshIframe()">
                    <i class="fa-solid fa-magnifying-glass fa-xs"></i>
                </button>
                <button class="btn btn-light" @click="zoom += zoomChange">
                    <i class="fa-solid fa-magnifying-glass-plus fa-xs"></i>
                </button>
                <button class="btn btn-light" @click="zoom -= zoomChange">
                    <i class="fa-solid fa-magnifying-glass-minus fa-xs"></i>
                </button>
            </div>
            <div class="col">
                Zoom {{ zoom }}%
            </div>
        </div>
        <iframe ref="iframePDF"
            v-if="pdfUrl"
            :src="generatedURL"
            width="100%"
            height="860px"
            style="border: none;"
            title="PDFViewer"
        ></iframe>
    </ModalComponent>
</template>

<script>

    import ModalComponent from "@/components/_shared/ModalComponent.vue";

    export default {
        name: "PDFViewer",
        components: {ModalComponent},
        props: {},
        data() {
            return {
                pdfUrl: null,
                pdfBlob: null,
                filename: "document.pdf",
                zoom: 100,
                zoomChange: 17.5,
            }
        },
        computed: {
            generatedURL() {
                this.$nextTick(() => { this.refreshIframe() });
                return `${this.pdfUrl}#toolbar=0&zoom=${this.zoom}`;
            },
        },
        methods: {
            open(response, filename) {
                const newBlob = new Blob([response.data], { type: 'application/pdf' });
                this.filename = `${filename}.pdf`;

                this.createObjectUrl(newBlob);
                this.$refs.modal.open();
            },
            createObjectUrl(blob) {
                this.closing();
                this.pdfUrl = URL.createObjectURL(blob);
            },
            closing() {
                if (this.pdfUrl) {
                    URL.revokeObjectURL(this.pdfUrl);
                    this.pdfUrl = null;
                    this.pdfBlob = null;
                    this.filename = "document.pdf";
                }
                this.zoom = 100;
            },
            downloadPDF() {
                const link = document.createElement('a');
                link.href = this.pdfUrl;
                link.download = this.filename;
                link.click();
            },
            printPDF() {
                this.$refs.iframePDF.contentWindow.print();
            },
            refreshIframe() {
                this.$refs.iframePDF?.contentWindow.location.reload(true);
            },
        },
        watch: {},
    }
</script>
