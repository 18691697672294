import io from 'socket.io-client';

export default class RedisService {
    constructor(userId) {
        const isSecure = import.meta.env.VITE_API_SECURITY === 'https';
        this.socket = io(`${import.meta.env.VITE_API_SECURITY || 'http'}://${import.meta.env.VITE_API_ENDPOINT}:${import.meta.env.VITE_APP_SOCKET_IO_PORT}`, {secure: isSecure});
        this.userId = userId;
    }

    listenForKeyUpdates(key, callback) {
        this.socket.on(`${import.meta.env.VITE_APP_ENV}_database_cache-channel:cache-updated`, (data) => {
            if (data.key === key) {
                callback(data.value);
            }
        });

        // this.socket.on("connect", () => {
        //     console.log(`Socket connected for: ${key}`);
        // });
        //
        // this.socket.on("disconnect", (reason, description) => {
        //     console.log(`Socket disconnected, reason: ${reason}, description: `, description);
        // });
        //
        // this.socket.on("connect_error", (err) => {
        //     console.log(`connect_error due to ${err.message}`);
        // });
        //
        // this.socket.onAny((event) => {
        //     console.log(`ANY ${event}`);
        // });
    }

    handleRouteChange(user, newRoute) {
        this.socket.emit('routeChange', { user: user, route: newRoute });
    }

    handleDeleteUser() {
        this.socket.emit('userDisconnected', { userId: this.userId });
    }

    disconnect() {
        this.handleDeleteUser()
        this.socket.disconnect();
    }
}
