import { defineStore } from "pinia";

import AxiosClass from "../classes/axios.js";
const axios = new AxiosClass();
export const useBadgeStore = defineStore('badge', {
    state: () => ({
        badgeCount: 0,
    }),
    getters: {
    },
    actions: {
        getAllBadges() {
            axios.get('/GetAllBadges').then((response) => {
                this.badgeCount = response;
            });
        },
        clear() {
            this.$reset();
        },
    },
    persist: {
        storage: sessionStorage,
        paths: ['badgeCount'],
    },
})