<style scoped>

</style>

<template>
    <ModalComponent ref="modal" title="Mémos Futures" size="modal-xl" :confirm="true">
        <table class="table table-hover table-bordered text-center text-xs">
            <thead>
            <tr :class="`bg-${this.$route.meta.theme}`">
                <th></th>
                <th>Date</th>
                <th>Sujet</th>
                <th>Contenu</th>
                <th>À</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="memo in orderedMemos" class="align-middle">
                <td>
                    <div class="btn-group btn-group-sm">
                        <button class="btn btn-light btn-sm" v-tooltip data-bs-toggle="tooltip" @click.prevent="deleteMemo(memo)"
                                data-bs-placement="top" data-bs-title="Effacer" data-bs-trigger="hover"
                        >
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </div>
                </td>
                <td>{{ this.$general.formatDate(memo.date) }}</td>
                <td>{{ memo.subject }}</td>
                <td class="text-start"><div v-html="memo.content"></div></td>
                <td>
                    <template v-for="recipient in getAllRecipients(memo)">
                        <div>{{ recipient.full_name }}</div>
                    </template>
                </td>
            </tr>
            </tbody>
        </table>
    </ModalComponent>
</template>

<script>

import ModalComponent from "@/components/_shared/ModalComponent.vue";

export default {
    name: "FutureMemo",
    components: {ModalComponent},
    props: {},
    data() {
        return {
            memos: [],
        }
    },
    computed: {
        orderedMemos() {
            return this.$general.orderBy(this.memos, 'date');
        }
    },
    methods: {
        async open() {
            this.memos = await this.$store.memoStore.getFutureMemos();
            this.$refs.modal.open();
        },
        getAllRecipients(memo) {
            let recipients = [];

            memo.recipients.forEach(recipient => {
                recipients.push(recipient.employe);
            });

            return this.$general.orderByCustom(recipients, 'full_name', true);
        },
        async deleteMemo(memo) {
            memo.recipients.forEach(recipient => {
                recipient.is_deleted = true;
            });

            await this.$store.memoStore.updateRecipient(memo);
            this.memos = await this.$store.memoStore.getFutureMemos();
        },
    },
    watch: {},
}
</script>