import { createApp } from 'vue'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router/index';

import './style.css';

// - START - DAYS JS IMPORT SECTION
import dayjs from 'dayjs';
import frCA from 'dayjs/locale/fr-ca';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.locale(frCA);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(dayjsBusinessDays);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs().localeData();
dayjs.updateLocale("fr-ca", {
    weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
});

// - END - DAY JS IMPORT SECTION

import './scss/styles.scss';
// Import all of Bootstrap's JS
// J'ai besoin de commenté ceci pour que sa fonctionne??
// import * as bootstrap from '~bootstrap'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import AxiosClass from '@/classes/axios.js';
const axios = new AxiosClass();

import { tooltip } from '@/classes/tooltip.js';

import General from "@/classes/general.js";
const generalFunctions = new General();

import StatusIcon from '@/classes/statusIcon.js';
const statusIconFunctions = new StatusIcon();

const app = createApp(App)
    .use(router)
    .use(pinia);

// Doit être séparer pour ajouter la config.
app.provide('axios', axios);
app.config.globalProperties.$axios = axios;

app.provide('dayJS', dayjs);
app.config.globalProperties.$dayjs = dayjs;

app.provide('general', generalFunctions);
app.config.globalProperties.$general = generalFunctions;

app.provide('statusIcon', statusIconFunctions);
app.config.globalProperties.$statusIcon = statusIconFunctions;

app.config.globalProperties.$store = {};

app.directive('tooltip', tooltip);

app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
