<style scoped>
    .stack-i .fa-arrow-right {
        position: absolute;
        top: 5px;
        left: 7px;
    }
    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
</style>

<template>
    <div v-if="!!isOrder">
        <div v-html="title"></div>
        <hr class="mt-0">
        <table class="table table-bordered table-hover">
            <thead>
            <tr class="text-center bg-supplying">
                <th style="width: 30px"></th>
                <th style="width: 30px"></th>
                <th style="width: 90px;"># PO</th>
                <th style="width: 150px;">Fournisseur</th>
                <th>Réquisition</th>
                <th>Contact</th>
                <th>Destination</th>
                <th>Adresse</th>
                <th>Date&nbsp;Commandée</th>
                <th>Livraison&nbsp;Confirmée</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in list" class="align-middle">
                <td class="text-center">
                    <template v-if="order.moNumber === null">
                        <input type="checkbox" v-model="order.is_order">
                    </template>
                    <template v-else-if="!order.moNumber.includes('BC') && !order.moNumber.includes('BI')">
                        <button class="btn btn-light btn-sm" @click.prevent="deleteMaterialOperation(order)">
                            <i class="fa-solid fa-trash fa-xs"></i>
                        </button>
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="order.moNumber !== null && (order.moNumber.includes('BC') || order.moNumber.includes('BI'))">
                                    <span v-tooltip data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Matériel à recevoir" data-bs-trigger="hover">
                                        <i class="fa-solid fa-industry text-purple-600"></i>
                                    </span>
                    </template>
                    <template v-else>
                        <button class="btn btn-light btn-sm" disabled>
                            <i class="fa-solid fa-list-ol fa-xs"></i>
                        </button>
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="order.moNumber === null">
                        -
                    </template>
                    <template v-else>
                        <a href="javascript:void(0)" :class="(order.moType === this.MaterialOperationTypeEnum.BC.id) ? 'text-purple-600': 'text-blue-600'" @click="printMaterial(order, order.iteration)">
                            {{ order.moNumber }}<span v-if="order?.iteration > 0">.{{ order.iteration }}</span>
                        </a>
                    </template>
                </td>
                <td>{{ order.cie_name }}</td>
                <td>{{ order.req_code }}</td>
                <td>
                    <select class="form-select form-select-sm" v-model="order.vendorContact"
                            :disabled="order.moNumber !== null">
                        <option v-for="contact in getContactForCie(order.cie_id, order)" :value="contact">
                            {{ contact.full_name }}
                        </option>
                    </select>
                </td>
                <td>
                    <select class="form-select form-select-sm" v-model="order.dispatch_received_cie_id"
                            @change="order.delivery_address_id = undefined" :disabled="order.moNumber !== null">
                        <option v-for="cie in cies" :value="cie.id">{{ cie.name }}</option>
                    </select>
                </td>
                <td>
                    <select class="form-select form-select-sm" v-model="order.delivery_address_id"
                            :disabled="order.moNumber !== null">
                        <option v-for="address in getDeliveryAddresses(order.dispatch_received_cie_id, order)"
                                :value="address.id">{{ address.full_name_delivery }}
                        </option>
                    </select>
                </td>
                <td class="text-center">
                    <template v-if="order.moNumber !== null">
                        {{ this.$general.formatDate(order.date) }}
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="order.moNumber !== null">
                        {{ this.$general.formatDate(order.date_delivery_confirmed) }}
                    </template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else-if="!!isReturn">
        <div v-html="title"></div>
        <hr class="mt-0">
        <table class="table table-bordered table-hover">
            <thead>
            <tr class="text-center bg-supplying">
                <th style="width: 30px"></th>
                <th style="width: 30px"></th>
                <th style="width: 90px;"># PO</th>
                <th style="width: 150px">Fournisseur</th>
                <th>Réquisition</th>
                <th>Contact</th>
                <th>Destination</th>
                <th>Adresse</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="returnInfo in list" class="align-middle">
                <td class="text-center">
                    <template v-if="returnInfo.moNumber === null">
                        <input type="checkbox" v-model="returnInfo.is_return">
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="returnInfo.moNumber !== null && returnInfo.moNumber.includes('RM')">
                                <span class="stack stack-i">
                                    <i class="fa-solid fa-industry text-purple-600"></i>
                                    <i class="fa-solid fa-arrow-right text-red-600 fa-stack-1x"></i>
                                </span>
                    </template>
                    <template v-else>
                        <button class="btn btn-light btn-sm" disabled>
                            <i class="fa-solid fa-list-ol fa-xs"></i>
                        </button>
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="returnInfo.moNumber === null">
                        -
                    </template>
                    <template v-else>
                        <a href="javascript:void(0)" :class="(returnInfo.moType === this.MaterialOperationTypeEnum.RM.id) ? 'text-purple-600': 'text-blue-600'" @click="printMaterial(returnInfo)">
                            {{ returnInfo.moNumber }}
                        </a>
                    </template>
                </td>
                <td>{{ returnInfo.cie_name }}</td>
                <td>{{ returnInfo.req_code }}</td>
                <td>
                    <select class="form-select form-select-sm" v-model="returnInfo.vendorContact" :disabled="returnInfo.moNumber !== null">
                        <option v-for="contact in getContactForCie(returnInfo.cie_id, returnInfo)" :value="contact">{{ contact.full_name }}</option>
                    </select>
                </td>
                <td>
                    <select class="form-select form-select-sm" v-model="returnInfo.cie_id" @change="returnInfo.delivery_address_id = undefined" disabled>
                        <option v-for="cie in cies" :value="cie.id">{{ cie.name }}</option>
                    </select>
                </td>
                <td>
                    <select class="form-select form-select-sm" v-model="returnInfo.delivery_address_id"
                            :disabled="returnInfo.moNumber !== null">
                        <option v-for="address in getDeliveryAddresses(returnInfo.cie_id, returnInfo)" :value="address.id">{{ address.full_name_delivery }}</option>
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else-if="!!isInventoryReturn">
        <div v-html="title"></div>
        <hr class="mt-0">
        <table class="table table-bordered table-hover">
            <thead>
            <tr class="text-center bg-supplying">
                <th style="width: 30px"></th>
                <th style="width: 30px"></th>
                <th style="width: 90px;"># PO</th>
                <th>Réquisition</th>
                <th>Responsable</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="returnInfo in list" class="align-middle">
                <td class="text-center" style="height: 38.38px;">
                    <template v-if="returnInfo.moNumber === null">
                        <input type="checkbox" v-model="returnInfo.is_return">
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="returnInfo.moNumber !== null && returnInfo.moNumber.includes('RM')">
                                <span class="stack stack-i">
                                    <i class="fa-solid fa-industry text-purple-600"></i>
                                    <i class="fa-solid fa-arrow-right text-red-600 fa-stack-1x"></i>
                                </span>
                    </template>
                    <template v-if="returnInfo.moNumber !== null && returnInfo.moNumber.includes('RI')">
                            <span class="stack">
                                <img src="@/assets/shelf.svg" class="ps-1" alt="" style="max-width: 20px">
                                <i class="fa-solid fa-arrow-right text-red-600 fa-stack-1x" style="left: 3px; top:9px"></i>
                            </span>
                    </template>
                    <template v-else>
                        <button class="btn btn-light btn-sm" disabled>
                            <i class="fa-solid fa-list-ol fa-xs"></i>
                        </button>
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="returnInfo.moNumber === null">
                        -
                    </template>
                    <template v-else>
                        <a href="javascript:void(0)" class='text-blue-600' @click="printMaterial(returnInfo)">
                            {{ returnInfo.moNumber }}
                        </a>
                    </template>
                </td>
                <td>{{ returnInfo.req_code }}</td>
                <td>{{ returnInfo.creator_full_name }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else-if="!!isHistory">
        <div v-html="title"></div>
        <hr class="mt-0">
        <table class="table table-bordered table-hover">
            <thead>
                <tr class="text-center bg-supplying">
                    <th style="width: 30px"></th>
                    <th style="width: 30px"></th>
                    <th style="width: 90px;"># PO</th>
                    <th style="width: 150px;">Fournisseur</th>
                    <th>Réquisition</th>
                    <th>Contact</th>
                    <th>Destination</th>
                    <th>Adresse</th>
                    <th>Date&nbsp;Commandée</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="mo in list" class="align-middle">
                    <td class="text-center">
                        <input type="checkbox" v-model="mo.is_order">
                    </td>
                    <td class="text-center"></td>
                    <td class="text-center">
                        <a href="javascript:void(0)" class="text-purple-600" @click="printMaterial(mo.material_operation, mo.iteration)">
                            {{ mo.material_operation.type.prefix }}-{{ mo.material_operation.operation_number }}.{{ mo.iteration }}
                        </a>
                    </td>
                    <td class="text-center">
                        {{ mo.material_operation.from_cie.name }}
                    </td>
                    <td class="text-center">
                        {{ mo.material_operation.title }}
                    </td>
                    <td>
                        <select class="form-select form-select-sm" v-model="mo.material_operation.from_cie_contact" disabled>
                            <option v-for="contact in getContactForCie(mo.material_operation.supplier_cie_id, mo.material_operation)" :value="contact">
                                {{ contact.full_name }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="form-select form-select-sm" v-model="mo.material_operation.dispatch_received_cie_id" disabled>
                            <option v-for="cie in cies" :value="cie.id">{{ cie.name }}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-select form-select-sm" v-model="mo.material_operation.delivery_address_id" disabled>
                            <option v-for="address in getDeliveryAddresses(mo.material_operation.dispatch_received_cie_id, mo.material_operation)"
                                    :value="address.id">{{ address.full_name_delivery }}
                            </option>
                        </select>
                    </td>
                    <td class="text-center">
                        {{ this.$general.formatDate(mo.date) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    export default {
        name: 'OrderPopupList',
        props: {
            title: String,
            list: Array,
            cies: Array,
            isOrder: Boolean,
            isReturn: Boolean,
            isInventoryReturn: Boolean,
            isHistory: Boolean,
        },
        data() {
            return {};
        },
        computed: {
            MaterialOperationTypeEnum() {
                return this.$store.enumsStore.MaterialOperationType;
            },
            AddressTypeEnum() {
                return this.$store.enumsStore.AddressType;
            }
        },
        methods: {
            getContactForCie(cieId, details) {
                if (this.cies.length === 0) { return; }

                const cie = this.cies?.find(cie => cie.id === cieId);

                if (details.vendorContact === undefined || Object.keys(details.vendorContact).length === 0) {
                    if (details.bc_contact_id  > 0) {
                        details.vendorContact = cie.contact.find(contact => contact.id === details.bc_contact_id);
                    } else if (cie.bc_contact_id > 0) {
                        details.vendorContact = cie.contact.find(contact => contact.id === cie.bc_contact_id);
                    } else {
                        details.vendorContact = cie.contact[0];
                    }
                }

                return this.$general.orderByCustom(cie.contact, 'full_name', true);;
            },
            getDeliveryAddresses(cieId, details) {
                const cie = this.cies.find(cie => cie.id === cieId);
                let addresses = cie?.address?.filter(address => address.address_type_id === this.AddressTypeEnum.SHIPPING.id);

                if (!!addresses && addresses.length > 0) {
                    addresses.forEach(address => {
                        address.full_name_delivery = `Livraison ${address.order} (${address.address})`;
                    });

                    if (details.delivery_address_id === null) {
                        details.delivery_address_id = addresses[0].id;
                    }
                } else {
                    addresses = [];
                }

                return addresses;
            },
            printMaterial(materialOperation, moHistoryIteration = null) {
                let params;
                if (moHistoryIteration) {
                    params = {id: materialOperation.id, moHistoryIteration: moHistoryIteration};
                } else {
                    params = {id: materialOperation.mo_id};
                }
                this.$parent.$parent.showPrintPopup();
                this.$axios.pdf(
                    ((materialOperation.moType >= this.MaterialOperationTypeEnum.RM.id) ? '/PrintReturn' : '/PrintOrder'),
                    params
                ).then((response) => {
                    const filename = materialOperation.moNumber;
                    this.$store.pdfviewer.open(response, filename);
                    this.$parent.$parent.$refs.printToast.hide();
                });
            },
            deleteMaterialOperation(mo) {
                this.$axios.post('/MaterialOperationDelete', {moNumber: mo.moNumber}).then((response) => {
                    this.$parent.$parent.open();
                });
            },
        },
        watch: {},
    };
</script>