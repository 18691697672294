import {defineStore} from "pinia";

import AxiosClass from "../classes/axios.js";
import router from '@/router/index.js';
const axios = new AxiosClass();

export const usePermissionStore = defineStore('perms', {
    state: () => ({
        listOfPermission: [],
        currentUserPermissions: [],
        copyOfPermissions: [],
        viewAsRole: '',
    }),
    getters: {
        isUserInViewMode: (state) => state.copyOfPermissions.length > 0,
        getViewAsRole: (state) => state.viewAsRole,
    },
    actions: {
        async getPermissions() {
            if (this.listOfPermission.length > 0) return;

            await axios.get('/PermissionList').then((data) => {
                if (data !== undefined) {
                    this.listOfPermission = data;
                }
            });
        },
        async getUserPermissions(employeId) {
            if (this.currentUserPermissions.length > 0) return;

            await axios.post('/UserPermissions', {employe_id: employeId}).then((data) => {
                if (data !== undefined) {
                    this.currentUserPermissions = data;
                }
            });
        },
        canAccess(permissionName) {
            let hasAccess = false;
            let findUserPermissionIndex;

            if (typeof permissionName === 'object') {
                permissionName.forEach((name) => {
                    findUserPermissionIndex = this.currentUserPermissions.findIndex(permission => permission.name === name);
                    if (!hasAccess) { hasAccess = findUserPermissionIndex >= 0; }
                });
            } else {
                findUserPermissionIndex = this.currentUserPermissions.findIndex(permission => permission.name === permissionName);
                hasAccess = findUserPermissionIndex >= 0;
            }

            return hasAccess;
        },
        async useOtherRolePermissions(role) {
            this.resetPermissions();

            this.copyOfPermissions = [ ...this.currentUserPermissions];
            this.currentUserPermissions = role.permissions;
            this.viewAsRole = role.name;
            await this.setViewAsRoleSession(role.id);
        },
        async resetPermissions() {
            if (this.isUserInViewMode) {
                this.currentUserPermissions = [ ...this.copyOfPermissions];
                this.copyOfPermissions = [];
                this.viewAsRole = '';
                await this.setViewAsRoleSession(null);
            }
        },
        async setViewAsRoleSession(roleId) {
            await axios.post('/SaveViewAsRole', {roleId: roleId}).then((response) => {
                router.push({name: 'Home'});
            });
        },
        clear() {
            this.$reset();
        },
    },
    persist: {
        storage: sessionStorage,
        paths: ['listOfPermission', 'currentUserPermissions', 'copyOfPermissions', 'viewAsRole'],
    },
})