import { defineStore } from "pinia";

export const useRememberStore = defineStore('remember', {
    state: () => ({
        listOfPages: [],
    }),
    getters: {

    },
    actions: {
        addPage(name) {
            this.listOfPages.push({pageName: name});
        },
        removePage(pageName) {
            if (this.pageExists(pageName)) {
                this.listOfPages.splice(this.listOfPages.findIndex(page => page.pageName === pageName), 1);
            }
        },
        updatePage(name, field, value) {
            if(!this.pageExists(name)) { this.addPage(name); }
            let page = this.getPage(name);
            page[field] = value;
        },
        getFieldValue(name, field) {
            let page = this.getPage(name);
            if(page === undefined) { return; }

            return page[field];
        },
        pageExists(pageName) {
            return this.listOfPages.findIndex(page => page.pageName === pageName) >= 0;
        },
        getPage (pageName) {
            return this.listOfPages.find(page => page.pageName === pageName);
        },
        clear() {
            this.$reset();
        },
    },
    persist: {
        storage: localStorage,
        paths: ['listOfPages'],
    },
})