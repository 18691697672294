import {defineStore} from "pinia";

import AxiosClass from "@/classes/axios.js";
import General from "@/classes/general.js";
const axios = new AxiosClass();
const general = new General();

const enumConfigurations = {
    MaterialOperationType: {
        additionalData: { XX: { id: -9999, step: -9999, status: -9999 } },
    }
};

export const useEnumsStore = defineStore('enums', {
    state: () => ({
        AddressType: [],
        ApprovalListType: [],
        BillType: [],
        CieType: [],
        EntryStatus: [],
        MaterialOperationType: [],
        ProjectType: [],
        RequisitionStatus: [],
        RequisitionType: [],
        TimeBankType: [],
        WageType: [],
        Tax: [],
        Language: [],
        InOutEvent: [],
        SupervisordStatus: [],
    }),
    actions: {
        async getEnum(enumName) {
            if(this[enumName].length === 0) {
                const config = enumConfigurations[enumName] || {};

                await axios.get(`/${enumName}`).then((response) => {
                    let data = response.reduce((acc, item) => {
                        acc[item.enum_key] = general.removeProperties(item, ['enum_key']);
                        return acc;
                    }, {});

                    if (config.additionalData) {
                        data = { ...data, ...config.additionalData };
                    }

                    this[enumName] = Object.freeze(data);
                });
            }
        },
        async loadEnums(enums) {
            const promises = enums.map(enumName => this.getEnum(enumName));
            await Promise.all(promises);
        },
        findInEnum(obj, searchKey, searchValue) {
            return (Object.entries(obj).find(([_, value]) => value[searchKey] === searchValue) || [])[1];
        },
        getProjectTypeBySection() {
            return [
                {group: Object.values(this.ProjectType).filter(type => type.section === 1)},
                {group: Object.values(this.ProjectType).filter(type => type.section === 2)}
            ];
        },
        getSupervisordStatus() {
            if(this.SupervisordStatus.length === 0) {
                this.SupervisordStatus = Object.freeze({
                    STOPPED: 'STOPPED',
                    STARTING: 'STARTING',
                    RUNNING: 'RUNNING',
                    BACKOFF: 'BACKOFF',
                    STOPPING: 'STOPPING',
                    EXITED: 'EXITED',
                    FATAL: 'FATAL',
                    UNKNOWN: 'UNKNOWN',
                });
            }
        },
        clear() {
            this.$reset();
        },
    },
    persist: {
        storage: sessionStorage,
        paths: [
            'AddressType', 'ApprovalListType', 'BillType', 'CieType', 'EntryStatus', 
            'MaterialOperationType', 'ProjectType', 'RequisitionStatus', 'RequisitionType', 
            'TimeBankType', 'WageType', 'Tax', 'Language', 'InOutEvent', 'SupervisordStatus'
        ],
    },
});