<style scoped>

</style>

<template>
    <ModalComponent ref="modal" title="Commander" size="modal-xxl" bg-color="supplying" :can-reset="true" :is-force-refresh="true">
        <div class="text-xs">
            <template v-if="moHistory.length > 0">
                <OrderPopupList title="Commandes Projets - <strong>Corrections</strong>" :list="moHistory" :cies="cies" :is-history="true"></OrderPopupList>
            </template>
            <OrderPopupList title="Commandes Projets" :list="orders" :cies="cies" :is-order="true"></OrderPopupList>
            <template v-if="ordersBE.length > 0">
                <OrderPopupList title="Commandes Externes" :list="ordersBE" :cies="cies" :is-order="true"></OrderPopupList>
            </template>
            <template v-if="projectReturn.length > 0">
                <OrderPopupList title="Retours Matériel" :list="projectReturn" :cies="cies" :is-return="true"></OrderPopupList>
            </template>
            <template v-if="inventoryReturn.length > 0">
                <OrderPopupList title="Retours Inventaire" :list="inventoryReturn" :cies="cies" :is-inventory-return="true"></OrderPopupList>
            </template>
        </div>
    </ModalComponent>
    <ToastComponent ref="toast"></ToastComponent>
    <ToastComponent ref="printToast" :is-bottom="true"></ToastComponent>
</template>

<script>

import ModalComponent from "@/components/_shared/ModalComponent.vue";
import ToastComponent from "@/components/_shared/ToastComponent.vue";
import OrderPopupList from '@/components/order/OrderPopupList.vue';
export default {
    name: "OrderPopup",
    components: { OrderPopupList, ToastComponent, ModalComponent},
    props: {},
    data() {
        return {
            orders: [],
            ordersBE: [],
            returns: [],
            projectReturn: [],
            inventoryReturn: [],
            moHistory: [],
            cies: [],
            dataChanged: true, // DO NOT REMOVE, NEEDED TO SAVE
        }
    },
    computed: {
        MaterialOperationTypeEnum() {
            return this.$store.enumsStore.MaterialOperationType;
        },
        AddressTypeEnum() {
            return this.$store.enumsStore.AddressType;
        },
        defaultCie() {
            return parseInt(this.$store.constantStore.getConstantByVariable('NO_CIE_DEFAULT').value);
        }
    },
    methods: {
        async getWaitingOrders() {
            await this.$axios.get('/OrderWaiting').then((response) => {
                this.orders = response;
            });
        },
        async getWaitingOrdersBE() {
            await this.$axios.get(`/OrderWaiting/${true}`).then((response) => {
                this.ordersBE = response;
            });
        },
        async getAllCies() {
            await this.$axios.get('/CieActiveWithDetails').then((response) => {
                this.cies = response;
            });
        },
        async getWaitingReturns() {
            await this.$axios.get('/ReturnWaiting').then((response) => {
                this.projectReturn = response.filter(project => project.return_type === this.MaterialOperationTypeEnum.RM.id);
                this.inventoryReturn = response.filter(project => project.return_type === this.MaterialOperationTypeEnum.RI.id);
            });
        },
        async getWaitingMoHistory() {
            await this.$axios.get('/MoHistoryWaiting').then((response) => {
                this.moHistory = response;
            });
        },
        getContactForCie(cieId, details) {
            if (this.cies.length === 0) { return; }

            const cie = this.cies?.find(cie => cie.id === cieId);

            if (details.vendorContact === undefined || Object.keys(details.vendorContact).length === 0) {
                if (details.bc_contact_id  > 0) {
                    details.vendorContact = cie.contact.find(contact => contact.id === details.bc_contact_id);
                } else if (cie.bc_contact_id > 0) {
                    details.vendorContact = cie.contact.find(contact => contact.id === cie.bc_contact_id);
                } else {
                    details.vendorContact = cie.contact[0];
                }
            }

            return cie.contact;
        },
        getDeliveryAddresses(cieId, details) {
            const cie = this.cies.find(cie => cie.id === cieId);
            let addresses = cie?.address?.filter(address => address.address_type_id === this.AddressTypeEnum.SHIPPING.id);

            if (!!addresses && addresses.length > 0) {
                addresses.forEach(address => {
                    address.full_name_delivery = `Livraison ${address.order} (${address.address})`;
                });

                if (details.delivery_address_id === null) {
                    details.delivery_address_id = addresses[0].id;
                }
            } else {
                addresses = [];
            }

            return addresses;
        },
        async open() {
            await this.getAllCies();
            await this.setupOrdersReturns();

            this.$refs.modal.open();            
        },
        async setupOrdersReturns() {
            await this.getWaitingOrders();
            await this.getWaitingOrdersBE();
            await this.getWaitingMoHistory();
            await this.getWaitingReturns();

            this.orders.forEach(order => {
                if (order.moNumber === null) {
                    order.dispatch_received_cie_id = this.defaultCie;
                    order.date_estimated_delivery = this.$general.getToday();
                } else {
                    order.date_estimated_delivery = this.$general.formatDate(order.date_estimated_delivery);
                }
            });

            this.ordersBE.forEach(order => {
                if (order.moNumber === null) {
                    order.dispatch_received_cie_id = this.defaultCie;
                    order.date_estimated_delivery = this.$general.getToday();
                } else {
                    order.date_estimated_delivery = this.$general.formatDate(order.date_estimated_delivery);
                }
            });

            this.inventoryReturn.forEach(returnInfo => {
                if (returnInfo.moStatus !== this.MaterialOperationTypeEnum.RM.status) {
                    returnInfo.moNumber = null;
                    returnInfo.date_estimated_delivery = this.$general.getToday();
                    returnInfo.delivery_address_id = null;
                } else {
                    returnInfo.date_estimated_delivery = this.$general.formatDate(returnInfo.date_estimated_delivery);
                }
                returnInfo.cie = this.cies.find(cie => cie.id === returnInfo.cie_id);
            });
            
            this.projectReturn.forEach(returnInfo => {
                if (returnInfo.moStatus !== this.MaterialOperationTypeEnum.RM.status) {
                    returnInfo.moNumber = null;
                    returnInfo.date_estimated_delivery = this.$general.getToday();
                    returnInfo.delivery_address_id = null;
                } else {
                    returnInfo.date_estimated_delivery = this.$general.formatDate(returnInfo.date_estimated_delivery);
                }
                returnInfo.cie = this.cies.find(cie => cie.id === returnInfo.cie_id);
            });
        },
        async save() {
            const orders = this.orders.filter(order => order.is_order);
            const ordersBE = this.ordersBE.filter(order => order.is_order);
            const projectReturn = this.projectReturn.filter(returnInfo => returnInfo.is_return);
            const inventoryReturn = this.inventoryReturn.filter(returnInfo => returnInfo.is_return);
            const moHistory = this.moHistory.filter(moHistory => moHistory.is_order)

            if (orders.length === 0 && ordersBE.length === 0 && inventoryReturn.length === 0 && projectReturn.length === 0 && moHistory.length === 0) {
                this.$refs.toast.setEverything('Commander','',`Vous n'avez pas sélectionnné de commandes ou de retours.`,'info');
                this.$refs.toast.show();
                return;
            } else {
                // Needed to wait before reset the modal
                await this.saveOperations(orders, ordersBE, projectReturn, inventoryReturn, moHistory);

                this.reset();
                await this.setupOrdersReturns();
            }
        },
        async saveOperations(orders, ordersBE, projectReturn, inventoryReturn, moHistory) {
            if (moHistory.length > 0) {
                await this.$axios.post('/MoHistoryConfirm', {
                    moHistory: moHistory,
                }).then((response) => {
                    this.$refs.toast.setEverything('Commander','','Vos commandes ont bien été corrigées','save');
                    this.$refs.toast.show();
                });
            }
            if (orders.length > 0) {
                await this.$axios.post('/OrderCreate', {
                    orders: orders,
                    isBE: false,
                }).then((response) => {
                    this.$refs.toast.setEverything('Commander','','Vos commandes ont bien été ajoutées','save');
                    this.$refs.toast.show();
                });
            }

            if (ordersBE.length > 0) {
                await this.$axios.post('/OrderCreate', {
                    orders: ordersBE,
                    isBE: true,
                }).then((response) => {
                    this.$refs.toast.setEverything('Commander','','Vos commandes ont bien été ajoutées','save');
                    this.$refs.toast.show();
                });
            }

            if (projectReturn.length > 0) {
                await this.$axios.post('/ReturnCreate', {
                    returns: projectReturn,
                }).then( async (response) => {
                    this.$refs.toast.setEverything('Commander','','Vos retours ont bien été ajoutés','save');
                    this.$refs.toast.show();
                });
            }
            if (inventoryReturn.length > 0) {
                await this.$axios.post('/ReturnCreate', {
                    returns: inventoryReturn,
                }).then( async (response) => {
                    this.$refs.toast.setEverything('Commander','','Vos retours ont bien été ajoutés','save');
                    this.$refs.toast.show();
                });
            }
        },
        reset() {
            this.orders = [];
            this.inventoryReturn = [];
            this.projectReturn = [];  
        },
        showPrintPopup() {
            this.$refs.printToast.setEverything('Commander', '', 'Création du PDF en cours.', 'info', false);
            this.$refs.printToast.show();
        },
        deleteMaterialOperation(mo) {
            this.$axios.post('/MaterialOperationDelete', {moNumber: mo.moNumber}).then((response) => {
                this.open();
            });
        }
    },
    watch: {},
}
</script>